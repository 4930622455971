<template>
  <div class="pdata">
    <div class="pdata__info">
      <div class="pdata__info-selectors" v-if="project">
        <label class="pdata__label">
          Группа:
          <div style="display: flex; gap: 10px">
            <select class="pdata__input" style="display: block;width: 100%;" v-model="group">
              <option value="all">all</option>
              <option :value="g" :key="g" v-for="g in sortedGroups">{{ g }}</option>
            </select>
            <Btn v-if="group && (Role == 2)" :small="true" title="Удалить группу" @click.prevent.stop="this.$store.dispatch('deleteGroup')">
              <svg stoke-width="55" viewBox="0 0 25 25">
                <g transform='translate(3.966 3.568)'>
                  <path d='M0-.001l17.435 18.213M0 18.212L17.435 0' stroke-width='4.4' stroke-linecap='round'></path>
                </g>
              </svg>
            </Btn>
          </div>
        </label>
        <label class="pdata__label">
          Фильтры:
          <div>
            <textarea class="pdata__input" style="display: block;width: 100%;height: 100px;" v-model="filters" />
          </div>
        </label>
        <label class="pdata__label" style="display: flex; gap: 5px; align-items: center;">
          <input type="checkbox" v-model="strictFilter">
          Строгий фильтр
        </label>
        <label class="pdata__label">
          Диапазон дат:
          <div style="display: flex; gap: 5px; align-items: center;">
            <input type="text" v-model="stringdates[0]" placeholder="dd.mm.yyyy" v-maska data-maska="##.##.####">
            -
            <input type="text" v-model="stringdates[1]" placeholder="dd.mm.yyyy" v-maska data-maska="##.##.####">
          </div>
        </label>
        <div class="pdata__btn pdata__btn_spaced" v-if="project && Role == 2">
          <Btn class="pdata__btn" @click="$refs.file.click()">Загрузить файлы</Btn>
          <input class="pdata__file" ref="file" type="file" multiple name="excel" @change="sendFiles">
        </div>
        <label class="pdata__label" v-if="project && Role == 2">
          Фильтры загрузки:
          <div>
            <textarea class="pdata__input" style="display: block;width: 100%;height: 100px;" v-model="loadFilters" />
          </div>
        </label>
        <Btn v-if="project && Role == 2" :small="true" @click="deleteExcept">Удалить все домены кроме указанных</Btn>
      </div>
      <div class="pdata__info-data">
        <label v-if="project && dates.length" style="display: flex; align-items: center; gap: 5px">
          <span>График для топ-</span>
          <input style="width: 50px; text-align: center;" type="number" v-model="top">
        </label>
        <Apexchart v-if="project && dates.length" width="750" type="line" :options="chartOptions()" :series="series()" />
        <Table v-if="project && dates.length" />
      </div>
    </div>
  </div>
</template>

<script>
import Btn from './buttons/Btn.vue';
import { mapGetters, mapState } from 'vuex'
import Table from './Table.vue';
import Apexchart from 'vue3-apexcharts'
import { vMaska } from "maska"

export default {
  data: () => ({
    stringdates: ['01.01.2000', '01.01.2050']
  }),
  directives: { maska: vMaska },
  computed: {
    ...mapState(["folder", "project", "Role", "data"]),
    ...mapGetters(["compdates", "topnames", "dates"]),
    sortedGroups(){
      const groups = [...(this?.project?.groups || [])]
      groups.sort((a, b) => a.localeCompare(b))
      return groups
    },
    top: {
      get() {
        return this.$store.state.top
      },
      set(v) {
        if (('' + v).match('e'))
          v = parseInt(('' + v).replace(/e/g, ''))
        this.$store.commit('setTop', v)
      }
    },
    group: {
      get() {
        return this.$store.state.group
      },
      set(v) {
        this.$store.commit('setGroup', v)
      }
    },
    loadFilters: {
      get() {
        return this.$store.state.loadFilters
      },
      set(v) {
        this.$store.commit('setLoadFilters', v)
      }
    },
    filters: {
      get() {
        return this.$store.state.filters
      },
      set(v) {
        this.$store.commit('setFilters', v)
      }
    },
    strictFilter: {
      get() {
        return this.$store.state.strictFilter
      },
      set(v) {
        this.$store.commit('setStrictFilter', v)
      }
    },

  },
  components: { Btn, Table, Apexchart },
  methods: {
    async deleteExcept() {
      this.$store.dispatch('deleteExcept')
    },
    async sendFiles() {
      let a = new FormData()
      for (let file of this.$refs.file.files)
        a.append('files[]', file)
      a.append('folder', this.folder)
      a.append('project', this.project.name)
      a.append('filters', this.$store.getters.loadFilters)
      console.log(this.$store.getters.loadFilters)
      window.a = a
      try {
        await this.$store.dispatch('sendFiles', a)
      } catch (err) {
        alert(err)
      }
      await this.$store.dispatch('updateProjects', a)
      await this.$store.dispatch('updateData')
      this.$refs.file.value = ''
    },
    series() {
      let res = []
      for (let name of this.topnames)
        res.push({
          name: name.replace(/^https?:\/\//, ''),
          data: this.data[name]
        })
      return res
    },
    transformseries(series) {
      let obj = {}
      for (let el of series)
        obj[el.name] = el.data
      return obj
    },
    selectColor(number) {
      const hue = number * 137.508
      return `hsl(${hue},50%,75%)`
    },
    getColors(number) {
      let a = []
      for (let i = 1; i <= number; i++)
        a.push(this.selectColor(i))
      return a
    },
    maxseries(series) {
      let res = 1
      for (let s of series)
        res = Math.max(res, ...s.data)
      return res
    },
    chartOptions() {
      return {
        chart: {
          animations: {
            enabled: false,
          },
          height: 400,
          type: 'line',
          zoom: {
            enabled: false
          }
        },
        colors: this.getColors(this.topnames.length),
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'straight',
        },
        grid: {
          row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5
          },
        },
        xaxis: {
          categories: this.compdates,
        },
        yaxis: {
          min: 0,
          max: this.maxseries(this.series())
        },
        title: {
          text: 'Число Фраз',
          align: 'center',
          style: {
            color: '#444'
          }
        }
      }
    },

  },
  watch: {
    group(v, old) {
      if (v && (v != old)) {
        this.$store.commit('setDomain', null)
        this.$store.dispatch('updateData')
      }
    },
    stringdates: {
      handler: function (v) {
        if (!v[0].match(/\d\d\.\d\d\.\d\d\d\d/)) return false
        if (!v[1].match(/\d\d\.\d\d\.\d\d\d\d/)) return false
        let res = v.map(el => el.split('.')
          .map((el, i) => {
            switch (i) {
              case 0: return parseInt(el)
              case 1: return parseInt(el) * 32
              case 2: return parseInt(el) * 500
            }
          })
          .reduce((a, b) => a + b, 0))
        this.$store.commit('setDateFilters', res)
      },
      deep: true
    },
  }
}
</script>

<style lang="sass" scoped>
.pdata
  padding-top: 30px
  display: flex
  flex-direction: column
  &__getdata
    width: 100% !important
    min-width: 100% !important
    max-width: 100% !important
    flex-shrink: 0 !important
  .filler
    flex-grow: 1
  &__label
    display: block
    input[placeholder="dd.mm.yyyy"]
      width: 90px
  &__input
    resize: none
    padding: 5px 8px
    border-radius: 4px
    border: 1px solid rgba(0,0,0,.4)
    outline: none
    display: block
    width: 100%
    &:not([type=text]):not(textarea)
      cursor: pointer
    &:focus
      border-color: rgba(0,0,0,.8)
  &__info
    display: flex
    flex-grow: 1
    align-items: stretch
    &-data
      flex-grow: 1
      display: flex
      flex-direction: column
    &-selectors
      min-width: 200px
      width: 200px
      position: sticky
      top: 10px
      min-height: calc(100vh - 80px)
      align-self: flex-start
      display: flex
      flex-direction: column
      margin-right: 15px
      >*
        margin-bottom: 10px
  &__doublebtns
    display: flex
    justify-content: space-between
    position: relative
    &-dash
      width: auto !important
      position: absolute
      left: 50%
      transform: translateX(-50%)
      top: 0
      font-size: 16px
      line-height: 30px
    >*
      width: calc(50% - 12.5px)
  &__file
    position: absolute
    opacity: 0
    pointer-events: none
  &__btn
    width: 100%
    align-self: stretch
    font-size: 18px
    &_spaced
      margin-top: 20px
</style>